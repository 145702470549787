import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "./button";

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;
  div {
    margin: 0 0px 40px;
  }
  input {
    max-width: 444px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: 1px solid #760f19;
    border-radius: 8px;

    padding: 0 15px;
    margin: 0 0 40px;
  }
  
  @media (max-width: 767px) {
    flex-direction: column;
    div {
      display: none;
    }
    input {
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const CaptchaBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 15px;
  border-radius: 8px;
  font-size: 20px;
`;

const CaptchaCode = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 4px 15px;
  border-radius: 8px;
  border: 2px solid #f9db37;
`;

const RefreshButton = styled.button`
  background: #760f19;
  border: none;
  color: #fff;
  font-family: inherit;
  font-size: 24px !important;
  padding: 4px 4px 4px;
  border-radius: 4px;
  text-transform: uppercase;
  &:hover {
      background-color: #9f1828;
      cursor: pointer;
  }
`

const CaptchaInputStyle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;
  div {
    margin: 0 0px 40px;
  }
  input {
    max-width: 222px;
    width: 100%;
    height: 38px;
    line-height: 45px;
    border: 1px solid #760f19;
    border-radius: 8px;

    padding: 0 15px;
    margin: 0 0 40px;
  }
  
  @media (max-width: 767px) {
    flex-direction: column;
    div {
      display: none;
    }
    input {
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
  }
`;

export const ContactForm = ({ setSubmitted }) => {
  const nameInput = useRef();
  const emailInput = useRef();
  const phonenumberInput = useRef();
  const restaurantInput = useRef();
  const captchaInput = useRef();
  const [captchaCode, setCaptchaCode] = useState("");

  // Funktion, um ein neues Captcha zu generieren
  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptchaCode(captcha);
  };

  // Funktion für das erste Laden des Captchas
  React.useEffect(() => {
    generateCaptcha();
  }, []);

  const submitForm = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    // Überprüfen, ob der Captcha-Code korrekt ist
    if (captchaInput.current.value !== captchaCode) {
      alert("Die Eingabe war leider nicht korrekt. Bitte tragen Sie die 6 Zeichen aus dem gelben Kasten in das Feld mit der Beschriftung 'Zeichenfolge' ein.");
      generateCaptcha(); // Neues Captcha generieren
      return false;
    }

    fetch(`https://7oj07qp452.execute-api.eu-central-1.amazonaws.com/v1//mainorder-contact-form`, {
      method: "post",
      body: JSON.stringify({
        name: nameInput.current.value,
        email: emailInput.current.value,
        phonenumber: phonenumberInput.current.value,
        restaurant: restaurantInput.current.value,
        unwichtig: -81337,
      }),
    })
      .then((r) => {
        console.log(r.data);
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("Fehler beim Absenden:", error);
        alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
      });
    return false;
  };

  return (
    <div>
      <form>
        <InputGroup>
          <input
            ref={nameInput}
            type={"text"}
            required
            name="name"
            placeholder={"Vorname, Nachname"}
          />
          <div style={{ width: "200px" }}>&nbsp;</div>
          <input
            ref={restaurantInput}
            type={"text"}
            required
            name="restaurant"
            placeholder={"Restaurant / Café"}
          />
        </InputGroup>
        <InputGroup>
          <input ref={emailInput} type={"text"} name="email" placeholder={"E-mail"} />
          <div style={{ width: "200px", textAlign: "center" }}>oder</div>
          <input
            ref={phonenumberInput}
            type={"text"}
            name={"phonenumber"}
            placeholder={"Telefon-Nr."}
          />
        </InputGroup>
        <Center>
        <CaptchaBox>
          <CaptchaCode>{captchaCode}</CaptchaCode>
          <RefreshButton onClick={generateCaptcha} type="button">
            ↻
          </RefreshButton>
        </CaptchaBox>
        </Center>
        <Center>
        <CaptchaInputStyle>
          <input
            ref={captchaInput}
            type={"text"}
            required
            name="captcha"
            placeholder={"Zeichenfolge"}
          />
        </CaptchaInputStyle>
        </Center>
        <Center>
          <Button onClick={submitForm} type={"button"}>
            ABSENDEN
          </Button>
        </Center>
      </form>
    </div>
  );
};
